






































































































































































































































import { Location } from 'vue-router';
import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '@vue/composition-api';
import { whenever } from '@vueuse/core';
import { dateToLongFormat, trimToPrecision } from '@/utils';
import TaskMetrics from './TaskMetrics.vue';
import { useSubmitDialog, useDeclineDialog } from './functions';
import { TaskKind } from '@/layers';
import IdItem from '@/components/molecules/IdItem.vue';
import {
  useActiveTaskAndKind,
  useAnnotationLayers
} from '../TaskAnnotateV2/functions';

export default defineComponent({
  components: {
    TaskMetrics,
    IdItem
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    taskId: {
      type: String,
      required: true
    },
    orgId: {
      type: String,
      required: true
    },
    taskKind: {
      type: String as PropType<TaskKind>,
      required: true
    }
  },
  setup(props) {
    const { taskId, projectId, orgId, taskKind } = toRefs(props);
    const { error, loading, layers, mutate } = useAnnotationLayers(
      taskKind,
      taskId
    );

    const taskAndKind = useActiveTaskAndKind(layers);
    const task = computed(() => taskAndKind.value?.task);

    const keyValuePairsLeft = computed(() => {
      const taskValue = task.value;
      if (!taskValue) {
        return [];
      }
      return [
        {
          key: 'Task ID',
          value: taskValue.id
        },
        {
          key: 'Batch ID',
          value: taskValue.batch.id
        },
        {
          key: 'Dataset ID',
          value: taskValue.dataset.id
        },
        {
          key: 'Project ID',
          value: taskValue.project.id
        },
        {
          key: 'Created by',
          value: taskValue.created_by?.name
        },
        {
          key: 'Created at',
          value: dateToLongFormat(new Date(taskValue.created_at))
        },
        {
          key: 'Submitted at',
          value: taskValue.submitted_at
            ? dateToLongFormat(new Date(taskValue.submitted_at))
            : 'N/A'
        }
      ];
    });

    const keyValuePairsRight = computed(() => {
      const taskValue = task.value;
      if (!taskValue) {
        return [];
      }

      const annotatorKeyValue =
        taskAndKind.value.kind === 'AnnotationTask'
          ? { key: 'Annotator', value: taskAndKind.value.task.annotator.name }
          : taskAndKind.value.kind === 'ReviewTask'
          ? { key: 'Reviewer', value: taskAndKind.value.task.assignee.name }
          : '';
      return [
        annotatorKeyValue,
        {
          key: 'Annotation view (task type)',
          value: taskValue.ui?.name
        },
        {
          key: 'Annotation view configuration',
          value: taskValue.configuration
            ? `Version ${taskValue.configuration.version}`
            : 'Default or latest'
        },
        {
          key: 'Annotation view guideline',
          value: taskValue.guideline
            ? `Version ${taskValue.guideline.version}`
            : 'Default or latest'
        },
        {
          key: 'Annotations',
          value: taskValue.counts.annotations
        },
        {
          key: 'Assets',
          value: taskValue.counts.assets
        }
      ];
    });

    const annotatedPercentage = computed(() => {
      if (!task.value) {
        return 0;
      }
      return (task.value.counts.annotations / task.value.counts.assets) * 100.0;
    });

    const routeBack = computed(
      () =>
        ({
          name: 'ProjectTasks',
          params: {
            projectId: projectId.value,
            orgId: orgId.value
          }
        } as Location)
    );

    const routeToTaskAnnotate = computed(() => {
      if (taskKind.value === 'AnnotationTask') {
        return {
          name: 'TaskAnnotate',
          params: {
            projectId: projectId.value,
            taskId: taskId.value
          }
        } as Location;
      } else if (taskKind.value === 'ReviewTask') {
        return {
          name: 'ReviewTaskAnnotate',
          params: {
            projectId: projectId.value,
            taskId: taskId.value
          }
        };
      } else {
        throw Error('Cannot annotate prediction task');
      }
    });

    const {
      success: showDeclineSuccess,
      isOpen: declineMenuOpen,
      error: errorDeclining,
      declining,
      declineTask,
      closeDialog: closeDeclineDialog
    } = useDeclineDialog(taskAndKind);

    whenever(showDeclineSuccess, function() {
      mutate();
      closeDeclineDialog();
    });

    const {
      success: showSubmitSuccess,
      isOpen: submitMenuOpen,
      error: errorSubmitting,
      submitting,
      submit: submitTask,
      canSubmit: canSubmitTask,
      closeDialog: closeSubmitDialog
    } = useSubmitDialog(taskAndKind);

    whenever(showSubmitSuccess, function() {
      closeSubmitDialog();
      mutate();
    });

    return {
      task,
      loading,
      error,
      keyValuePairsLeft,
      keyValuePairsRight,
      annotatedPercentage,
      trimToPrecision,
      routeBack,
      routeToTaskAnnotate,
      declineMenuOpen,
      declineTask,
      errorDeclining,
      declining,
      showDeclineSuccess,
      showSubmitSuccess,
      submitMenuOpen,
      errorSubmitting,
      submitting,
      submitTask,
      canSubmitTask
    };
  }
});
