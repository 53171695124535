



































































































import Vue from 'vue';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import LineChart from '@/components/LineChart.vue';
import LoadingModal from '@/components/LoadingModal.vue';
import _range from 'lodash/range';

import { AnnotationTaskMetrics, DurationMetricItem } from '@/types';

const moment = extendMoment(Moment as any);

export default Vue.extend({
  name: 'TaskMetrics',
  components: {
    LoadingModal,
    LineChart
  },
  props: {
    projectId: {
      required: true,
      type: String
    },
    taskId: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      loading: false as boolean,
      error: null as string | null,
      taskMetrics: null as AnnotationTaskMetrics | null,
      labelsSummary: {
        submitted: 0,
        remaining: 0,
        complete: 0
      } as Record<string, number>,
      durationsSummary: {
        count: 0,
        total: 0,
        average: 0,
        median: 0
      } as Record<string, number>,
      dropDownValues: {
        annotations: 'Daily'
      } as Record<string, string>,
      dropDownOptions: {
        annotations: ['Daily', 'Weekly', 'Monthly']
      } as Record<string, Array<string>>,
      chartStyle: {
        borderWidth: 2,
        borderColor: '#2876d5',
        backgroundColor: '#00000000'
      } as any,
      chartOptions: {
        annotations: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                gridLines: {
                  borderDash: [2, 2],
                  color: '#cccccc'
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  borderDash: [2, 2],
                  color: '#cccccc'
                }
              }
            ]
          }
        },
        durations: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                gridLines: {
                  borderDash: [2, 2],
                  color: '#cccccc'
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  borderDash: [2, 2],
                  color: '#cccccc'
                }
              }
            ]
          }
        }
      } as Record<string, any>,
      chartData: {
        annotations: {
          labels: [],
          datasets: []
        },
        durations: {
          labels: [],
          datasets: []
        }
      } as Record<string, any>
    };
  },
  created() {
    this.fetchTaskMetrics();
  },
  computed: {
    annotationCount(): number {
      return this.taskMetrics ? this.taskMetrics.annotation_count : 0;
    },
    assetCount(): number {
      return this.taskMetrics ? this.taskMetrics.asset_count : 0;
    },
    labelCount(): number {
      return this.taskMetrics ? this.taskMetrics.label_count : 0;
    },
    durationCount(): number {
      return this.durationItems.length;
    },
    indexes(): Array<number> {
      return _range(1, this.durationCount + 1, 1);
    },
    durations(): Array<number> {
      return this.durationItems
        .filter(item => 'total' in item)
        .map(item => moment.duration(item.total).asSeconds());
    },
    durationItems(): Array<DurationMetricItem> {
      return this.taskMetrics ? this.taskMetrics.durations.items : [];
    },
    durationTotal(): number {
      return this.taskMetrics
        ? moment.duration(this.taskMetrics.durations.total).asSeconds()
        : 0;
    },
    durationAverage(): number {
      return this.taskMetrics
        ? moment.duration(this.taskMetrics.durations.average).asSeconds()
        : 0;
    },
    durationMedian(): number {
      return this.taskMetrics
        ? moment.duration(this.taskMetrics.durations.median).asSeconds()
        : 0;
    },
    durationAverages(): Array<number> {
      return this.durationItems.map(item =>
        moment.duration(item.average).asSeconds()
      );
    },
    durationMedians(): Array<number> {
      return this.durationItems.map(item =>
        moment.duration(item.median).asSeconds()
      );
    }
  },
  methods: {
    async fetchTaskMetrics() {
      this.error = null;
      this.loading = true;

      try {
        this.taskMetrics = await this.$api.tasks.getTaskMetrics({
          taskId: this.taskId
        });
        if (!this.taskMetrics || !(this.durationItems.length > 0)) {
          return;
        }
        this.populateLabelsSummary();
        this.populateDailyAnnotations();
        this.populateDurations();
        this.populateDurationsSummary();
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
    onAnnotationRangeChanged(dateRange) {
      switch (dateRange) {
        case 'Daily': {
          this.populateDailyAnnotations();
          break;
        }
        case 'Weekly': {
          this.populateWeeklyAnnotations();
          break;
        }
        case 'Monthly': {
          this.populateMonthlyAnnotations();
          break;
        }
      }
    },
    populateLabelsSummary() {
      this.labelsSummary = {
        submitted: this.annotationCount,
        remaining: this.assetCount - this.annotationCount,
        complete: (this.annotationCount / this.assetCount) * 100
      };
    },
    populateDailyAnnotations() {
      const startOfMonth = moment().startOf('month');
      const endOfMonth = moment().endOf('month');
      const daysInMonth = moment.range(startOfMonth, endOfMonth).by('day');

      this.chartData.annotations = {
        labels: Array.from(daysInMonth).map(day => day.format('MM/DD/YYYY')),
        datasets: [
          {
            ...this.chartStyle,
            label: 'Daily',
            data: Array.from(daysInMonth).map(day =>
              this.labelCountByDate(day, 'day')
            )
          }
        ]
      };
    },
    populateWeeklyAnnotations() {
      const startOfYear = moment().startOf('year');
      const endOfYear = moment().endOf('year');
      const weeksInYear = moment.range(startOfYear, endOfYear).by('week');

      this.chartData.annotations = {
        labels: Array.from(weeksInYear).map(week => week.isoWeek()),
        datasets: [
          {
            ...this.chartStyle,
            label: 'Weekly',
            data: Array.from(weeksInYear).map(week =>
              this.labelCountByDate(week, 'week')
            )
          }
        ]
      };
    },
    populateMonthlyAnnotations() {
      const startOfYear = moment().startOf('year');
      const endOfYear = moment().endOf('year');
      const monthsInYear = moment.range(startOfYear, endOfYear).by('month');

      this.chartData.annotations = {
        labels: Array.from(monthsInYear).map(month =>
          month.startOf('month').format('MM/YYYY')
        ),
        datasets: [
          {
            ...this.chartStyle,
            label: 'Monthly',
            data: Array.from(monthsInYear).map(month =>
              this.labelCountByDate(month, 'month')
            )
          }
        ]
      };
    },
    populateDurationsSummary() {
      this.durationsSummary = {
        count: this.labelCount,
        total: this.durationTotal,
        average: this.durationAverage,
        median: this.durationMedian
      };
    },
    populateDurations() {
      this.chartData.durations = {
        labels: this.indexes,
        datasets: [
          {
            ...this.chartStyle,
            label: 'Duration',
            data: this.durations
          },
          {
            ...this.chartStyle,
            borderColor: '#ff3300',
            label: 'Average',
            data: this.durationAverages
          },
          {
            ...this.chartStyle,
            borderColor: '#009933',
            label: 'Median',
            data: this.durationMedians
          }
        ]
      };
    },
    labelCountByDate(date, granularity): number {
      const items = this.durationItems.filter(
        item =>
          moment(item.created_at).isSame(
            date.startOf(granularity),
            granularity
          ) && 'label_count' in item
      );

      return items.length > 0
        ? items.reduce((acc, cur) => acc + cur.label_count, 0)
        : 0;
    },
    formatDuration(durationSecs): string {
      const duration = moment.duration(durationSecs, 'seconds');
      const seconds = duration.seconds() + duration.milliseconds() / 1000;

      if (duration.hours()) {
        return `${duration.hours()}h${duration.minutes()}m${Math.round(
          seconds
        )}s`;
      } else if (duration.minutes()) {
        return `${duration.minutes()}m${Math.round(seconds)}s`;
      } else {
        return `${seconds.toFixed(1)}s`;
      }
    }
  }
});
