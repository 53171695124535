import { render, staticRenderFns } from "./TaskMetrics.vue?vue&type=template&id=91be4966&"
import script from "./TaskMetrics.vue?vue&type=script&lang=ts&"
export * from "./TaskMetrics.vue?vue&type=script&lang=ts&"
import style0 from "./TaskMetrics.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VCard,VCol,VContainer,VRow,VSelect,VSpacer})
